.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker {
    font-size: 1rem;
    font-family: unset;
}

.react-datepicker__current-month {
    height: 24px;
    font-size: 1.4rem;
}

.react-datepicker__month-container {
    width: 100%;
    height: 100%;
}

.react-datepicker__day-name {
    width: 32px;
    height: 24px;
    font-size: 1.4rem;
}

.react-datepicker__day {
    width: 32px;
    height: 32px;

    font-size: 1.4rem;

    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
}

.react-datepicker__month-read-view--selected-month {
    font-size: 1.4rem;

}

.react-datepicker__header__dropdown .react-datepicker__header__dropdown--scroll {
    padding-bottom: 16px;
}

.react-datepicker__month-option {
    font-size: 1.4rem;
}

.react-datepicker__week {
    display: flex;
}