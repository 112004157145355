.snackbar {
    visibility: hidden;
    min-width: 250px;
    margin-left: -250px;
    background-color: #990000;
    color: #fff;
    text-align: center;
    border-radius: 10px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 500px;
    font-size: 2rem;
    font-style: italic;
    opacity: 0.9;
}

.snackbar.show {
    visibility: visible; /* Show the snackbar */
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
   However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadein 0.5s, fadeout 0.5s 3s;
    animation: fadein 0.5s, fadeout 0.5s 3s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
    from {
        bottom: 500px;
        opacity: 0;
    }
    to {
        bottom: 500px;
        opacity: 0.9;
    }
}

@keyframes fadein {
    from {
        bottom: 500px;
        opacity: 0;
    }
    to {
        bottom: 500px;
        opacity: 0.9;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 500px;
        opacity: 0.8;
    }
    to {
        bottom: 500px;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: 500px;
        opacity: 0.9;
    }
    to {
        bottom: 500px;
        opacity: 0;
    }
}
