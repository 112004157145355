.react-autosuggest__suggestions-container {
    position: absolute;
    z-index: 300;

    background: white;
}

.react-autosuggest__suggestions-list {
    border: 1px solid #d3d3d3;
    margin: 0;
    padding: 4px;
    width: 100%
}

.react-autosuggest__suggestion {
    list-style: none;
    padding: 2px;
    margin: 0;
    display: block;
    outline: 0;

    cursor: pointer;
}

.react-autosuggest__suggestion:hover {
    color: #2a6496;
    text-decoration: underline;
    padding: 1px;

    border: 1px solid #aaaaaa;
}